@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  /* background-color: #040208; */
  background-color: #131217;
  color: white;
}
.pricebody {
  background-image: url(/public/price.png);
  background-color: #040208;
  background-repeat: no-repeat;
  background-size: cover;
  height: 1132px;
  background-position: center center;
}
.footer {
  background-image: url(/public/footer.png);
  background-color: #040208;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.body {
  background-image: url(/public/hero.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 140vh;
}

.servicess {
  height: 889px;
  width: 100%;
  background-repeat: no-repeat;
  background-image: url(../public/services.png);
  background-size: cover;
  background-position: center center;
}
.footerss {
  width: 100%;
  background-repeat: no-repeat;
  background-image: url(../public/footerbg.png);
  background-size: cover;
  background-position: center center;
}

.swiper-slide-custom {
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100%;
}
.slide-text {
  font-size: 24px;
  color: #a8a2a2d9;
  font-weight: 600;
  transition: font-size 0.3s, color 0.3s;
}
.swiper-slide-custom.swiper-slide-active .slide-text {
  font-size: 30px;
  font-weight: 700;
  color: #eeeeee;
  text-align: start;
}
.slick-next {
  right: 10px !important;
  visibility: hidden !important;
  border-radius: 100% !important;
  background: #fff !important;
}

.slick-prev {
  left: 11px !important;
  z-index: 99 !important;
  visibility: hidden !important;
  border-radius: 100% !important;
  background: #fff !important;
}
.slick-prev:before,
.slick-next:before {
  color: black !important;
  height: 77px !important;
  width: 77px !important;
}
.slick-dots {
  bottom: -31px !important;
}
.slick-dots li.slick-active button::before {
  bottom: -28px !important;
  opacity: 100% !important;
  color: #2244ff !important;
  background: #2244ff !important;
}

.slick-dots li button:before {
  height: 12px !important;
  width: 12px !important;
  border-radius: 100% !important;
  background: #fff !important;
  color: #fff !important;
}
.bg-clip-text {
  background: linear-gradient(253.74deg, #239ef8 0%, #e3204a 60%);
  -webkit-background-clip: text;
  background-clip: text;
}
.section-header {
  -webkit-text-stroke: 2px #eeeeee;
  color: transparent;
}

.p-sidebar-header {
  padding: 10px;
}

#triangle-bottomleft {
  width: 0;
  height: 0;
  border-bottom: 50px solid #134c75;
  border-right: 50px solid transparent;
}
#triangle-development {
  width: 0;
  height: 0;
  border-bottom: 50px solid #2f3a5a;
  border-right: 50px solid transparent;
}
#triangle-launch {
  width: 0;
  height: 0;
  border-bottom: 50px solid #d9315e;
  border-right: 50px solid transparent;
}
#triangle-design {
  width: 0;
  height: 0;
  border-bottom: 30px solid #134c75;
  border-right: 30px solid transparent;
}

::-webkit-scrollbar {
  height: 1px;
  width: 1px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb:horizontal {
  background: transparent;
  border-radius: 10px;
}
.scroll-to-top {
  background-color: #3869fc !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  right: 40px;
  bottom: 40px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
  border-radius: 100% !important;
  width: 45px !important;
  height: 45px !important;
  padding: 10px !important;
  box-shadow: 0 9px 25px 0 #3869fc;
  border: none;
}
/* input:focus {
  border-color: #2244ff !important;
} */
input[type="text"]:focus {
  border: 1px solid #2244ff !important;
  padding: 0 5px;
}
textarea:focus {
  outline: none !important;
  border-color: #2244ff;
}
select:focus {
  outline: none !important;
  border-color: #2244ff !important;
}

input:focus {
  outline: none;
  padding: 0 5px;
  border: 1px solid black;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.5);
}

@font-face {
  font-family: "clash_displaybold";
  src: url(../public/clashdisplaybold.woff);
}

.header-brand {
  font-family: "clash_displaybold";
}

.button {
  margin: 20px;
  font-size: 20px;
  height: 51px;
  width: 51px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-decoration: none;
}

.arrow {
  color: #eeeeee;
  border: 2px solid #eeeeee;
  margin: 2em 0;
  position: relative;
}

.arrow::after {
  display: inline-block;
  padding-left: 40px;
  content: "\2190"; /* Left arrow Unicode */
  font-size: 60px;
  transition: transform 0.3s ease-in;
  color: #eeeeee;
  margin-top: -24px;
}

.arrow:hover {
  background-color: #eeeeee;
}

.arrow:hover::after {
  color: #2244ff;
  transform: translateX(-20px);
}

.button {
  margin: 20px;
  font-size: 20px;
  height: 51px;
  width: 51px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-decoration: none;
}

.arrow-right {
  color: #eeeeee;
  border: 2px solid #eeeeee;
  margin: 2em 0;
  position: relative;
}

.arrow-right::after {
  display: inline-block;
  padding-right: 40px;
  content: "\2192"; /* Right arrow Unicode */
  font-size: 60px;
  transition: transform 0.3s ease-in;
  color: #eeeeee;
  margin-top: -24px;
}

.arrow-right:hover {
  background-color: #eeeeee;
}

.arrow-right:hover::after {
  color: #2244ff;
  transform: translateX(20px);
}

.swiper-pagination {
  visibility: hidden !important;
}

a#scroll-btn {
  /* position: absolute; */
  height: 6em;
  width: 2.5em;
  border: 0.16em solid #ffffff;
  margin: auto;
  left: -40rem;
  right: 0;
  top: 17.25em;
  border-radius: 3em;
}
a#scroll-btn::before {
  position: absolute;
  content: "";
  left: 0.76rem;
  right: 0%;
  top: 18em;
  height: 0.8em;
  width: 0.8em;
  border: 1px solid #396afd;
  background-color: white;
  border-radius: 50%;
  animation: move-down 2s infinite;
}
@keyframes move-down {
  80% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(3.3em);
    opacity: 0;
  }
}

.hh {
  width: 193px;
  height: 56px;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.hh:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2244ff;

  z-index: -2;
}

.hh:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #1f38c3;
  transition: all 0.3s;
  z-index: -1;
}

.hh:hover {
  color: #fff;
}

.hh:hover:before {
  width: 100%;
}
.bn2 {
  width: 136px;
  height: 43px;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.bn2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2244ff;

  z-index: -2;
}

.bn2:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #1f38c3;
  transition: all 0.3s;
  z-index: -1;
}

.bn2:hover {
  color: #fff;
}

.bn2:hover:before {
  width: 100%;
}
.p-sidebar-close {
  visibility: hidden !important;
}

@media (min-width: 768px) and (max-width: 880px) {
  .body {
    height: 205vh;
  }
}
@media (min-width: 669px) and (max-width: 768px) {
  .swiper-slide-custom {
    /* width: 320px !important; */
    margin-right: 0 !important;
    text-align: center !important;
  }
  .slide-text {
    font-size: 16px;
    text-align: center;
    text-wrap: nowrap;
  }
  .swiper-slide-custom.swiper-slide-active .slide-text {
    font-size: 18px;
    font-weight: 700;
    color: #eeeeee;

    text-align: center !important;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .slide-text {
    font-size: 20px;
  }
  .swiper-slide-custom.swiper-slide-active .slide-text {
    font-size: 22px;
    font-weight: 700;
  }
}
@media (min-width: 881px) and (max-width: 1024px) {
  .slide-text {
    font-size: 20px;
  }
  .swiper-slide-custom.swiper-slide-active .slide-text {
    font-size: 22px;
    font-weight: 700;
  }
}
@media (min-width: 769px) and (max-width: 880px) {
  .slide-text {
    font-size: 19px;
  }
  .swiper-slide-custom.swiper-slide-active .slide-text {
    font-size: 20px;
    font-weight: 700;
  }
}
@media (min-width: 769px) and (max-width: 880px) {
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 5% !important;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(
      --swiper-wrapper-transition-timing-function,
      initial
    );
    box-sizing: content-box;
  }
  .swiper-slide {
    width: 177.4px !important;
    margin-right: 30px;
  }

  .swiper-slide-custom {
    display: flex;
    justify-content: start;
    align-items: start;
    height: 50%;
    width: 400px !important;
  }
  .slide-text {
    font-size: 16px;
    color: #a8a2a2d9;
    font-weight: 600;
    transition: font-size 0.3s, color 0.3s;
  }
  .swiper-slide-custom.swiper-slide-active .slide-text {
    font-size: 20px;
    font-weight: 700;
    color: #eeeeee;
    text-align: start;
  }
}
@media (min-width: 881px) and (max-width: 1024px) {
  .body {
    height: 126vh;
  }
}
@media (min-width: 669px) and (max-width: 768px) {
  .body {
    height: 207vh;
  }
}
@media (min-width: 481px) and (max-width: 668px) {
  .body {
    height: 182vh;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .body {
    height: 150vh;
  }
}

@media (width: 320px) {
  .body {
    height: 150vh;
  }
}
@media (width: 390px) {
  .body {
    height: 138vh;
  }
}
@media (width: 360px) {
  .body {
    height: 154vh;
  }
}
@media (width: 820px) {
  .body {
    height: 128vh;
  }
}

@media (width: 912px) {
  .body {
    height: 68vh;
  }
}
@media (width: 853px) {
  .body {
    height: 120vh;
  }
}
@media (min-width: 300px) and (max-width: 480px) {
  .hh {
    width: 165px;
    height: 50px;
  }
  .button {
    height: 40px;
    width: 40px;
  }

  .arrow::after {
    font-size: 60px;
    margin-top: -24px;
  }

  .button {
    height: 40px;
    width: 40px;
  }
  .arrow-right::after {
    font-size: 60px;
    margin-top: -24px;
  }
}
